var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 font-17"},_vm._l((_vm.lessons),function(course_lessons){return _c('span',{key:course_lessons.id},[_c('b-row',[_c('b-col',{staticClass:"align-items-center",attrs:{"lg":"12"}},[(course_lessons.type === 'divide' )?_c('div',{staticClass:"lesson-topic py-1"},[_c('b',{staticClass:"mt-1 mx-2"},[_vm._v(_vm._s(course_lessons.name))])]):_vm._e(),(course_lessons.type === 'lesson')?_c('span',{staticClass:"pointer"},[_c('div',{class:!(_vm.selected && _vm.selected.id == course_lessons.id) 
          ? 'd-flex justify-content-between align-items-center grey-bg'
          : 'd-flex justify-content-between align-items-center lesson-bar',style:(_vm.preTestCheck(course_lessons)
          ? 'background: gray; opacity: 0.5; cursor: not-allowed;'
          : ''),on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.enrollCheck?_vm.lessonDetail(course_lessons, course_lessons.subject_id):null}}},[_c('div',{staticClass:"my-1 mx-2"},[_vm._v(" "+_vm._s(course_lessons.name.includes("บทเรียน") ? "" : "บทเรียน ")+" "+_vm._s(course_lessons.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center my-1 mx-2"},[(_vm.checkLessonProgress(course_lessons.id) )?_c('b-icon',{staticClass:"close mx-2",staticStyle:{"color":"green"},attrs:{"icon":"eye"}}):_vm._e(),(course_lessons.is_external_link &&
                course_lessons.external_link
                )?_c('div',{staticClass:"ml-1 mr-1"},[_c('button',{staticClass:"btn btn-sm main-orange-btn btn-block pill-btn",style:(_vm.preTestCheck(course_lessons)
                    ? 'white-space: nowrap; cursor: not-allowed;'
                    : 'white-space: nowrap;'),attrs:{"disabled":_vm.preTestCheck(course_lessons)},on:{"click":function($event){return _vm.downloadFile(course_lessons.external_link)}}},[_vm._v(" ลิ้งค์ระบบภายนอก ")])]):_vm._e(),(course_lessons.pdf)?[_c('button',{staticClass:"btn btn-sm main-orange-btn btn-block pill-btn ml-2 mr-2",style:(_vm.preTestCheck(course_lessons)
                    ? 'white-space: nowrap; cursor: not-allowed;'
                    : 'white-space: nowrap;'),attrs:{"disabled":_vm.preTestCheck(course_lessons)},on:{"click":function($event){return _vm.downloadFile(course_lessons.pdf.url)}}},[_vm._v(" Download เอกสาร ")])]:_vm._e(),_c('div',{staticStyle:{"min-width":"120px"},on:{"click":function($event){_vm.enrollCheck?_vm.lessonDetail(course_lessons, course_lessons.subject_id):null}}},[_c('i',{class:!(_vm.selected && _vm.selected.id == course_lessons.id)  
                  ? 'bi bi-pause-circle mr-2 font-25'
                  : 'bi bi-play-circle mr-2 font-25'}),_c('span',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.convertTominute(course_lessons.lesson_minute))+" ")])])],2)])]):_vm._e(),(course_lessons.type === 'quiz' || course_lessons.type === 'eval')?_c('span',{staticClass:"pointer"},[_c('div',{class:!(_vm.selected && _vm.selected.id == course_lessons.id) 
              ? ' justify-content-between align-items-center grey-bg w-full'
              : 'justify-content-between align-items-center lesson-bar w-full',style:('position: relative;' + course_lessons.question_sets
          ? !_vm.inTimeDurationWithMinute(
            course_lessons.question_sets.test_start_at,
            course_lessons.question_sets.time_limit_minute
          ) 
          || _vm.postTestCheck(course_lessons.question_sets) 
          || _vm.numtest(course_lessons.question_sets.repeatable, course_lessons.question_sets.repeat_number, _vm.getAnswerOfLessonbyid(course_lessons, _vm.answerCourse))
            ? 'background: gray; opacity: 0.5; cursor: not-allowed;'
            : ''
          : ''),on:{"click":function($event){_vm.enrollCheck
            ?_vm.goToQuiz(course_lessons.question_sets, _vm.answerCourse, course_lessons.question_sets.repeatable, course_lessons.question_sets.repeat_number, _vm.getAnswerOfLessonbyid(course_lessons, _vm.answerCourse))
            :null}}},[_c('div',{staticClass:"row w-full"},[_c('div',{staticClass:"col-6 col-md-8 my-auto"},[_c('div',{staticClass:"my-1 mx-2"},[_vm._v(" "+_vm._s(course_lessons.question_sets.test_type == "pretest" ? "(Pre Test)" : course_lessons.question_sets.test_type == "posttest" ? "(Post Test)" : "")+" "+_vm._s(course_lessons.name)+" ")])]),_c('div',{staticClass:"col-6 col-md-2 my-auto"},[_c('div',{staticClass:"d-flex my-1 mx-2"},[_c('button',{staticClass:"btn btn-outline-light text-dark",style:('min-width: 150px;'),attrs:{"disabled":!_vm.inTimeDuration(
                    _vm.courseEnroll?_vm.courseEnroll.enroll.course_start:null,
                    _vm.courseEnroll?_vm.courseEnroll.enroll.course_end:null,
                  ) 
                  || _vm.postTestCheck(course_lessons.question_sets) 
                  || _vm.numtest(
                    course_lessons.question_sets.repeatable, 
                    course_lessons.question_sets.repeat_number, 
                    _vm.getAnswerOfLessonbyid(course_lessons, _vm.answerCourse)
                  )}},[_c('i',{staticClass:"bi bi-pencil"}),_vm._v(" "+_vm._s(course_lessons.type === 'quiz' ? 'แบบทดสอบ' : 'แบบประเมิน')+" ")])])]),(course_lessons.type !='eval')?_c('div',{staticClass:"col-12 col-md-2 my-auto"},[_c('div',{staticClass:"d-flex my-auto mx-2"},[(_vm.getAnswerOfLesson(course_lessons, _vm.answerCourse))?_c('div',[(_vm.getAnswerOfLesson(course_lessons, _vm.answerCourse)
                      .is_checking
                      )?_c('div',{style:(`color:  ${course_lessons.question_sets
                        ? _vm.getAnswerOfLesson(course_lessons, _vm.answerCourse)
                          .user_score >=
                          _vm.getMaxQuestion(course_lessons, _vm.answerCourse)
                          ? 'green'
                          : 'red'
                        : 'gray'
                        };`)},[_vm._v(" คะแนนสอบ : "+_vm._s(_vm.getAnswerOfLesson(course_lessons, _vm.answerCourse) .user_score)+" / "+_vm._s(_vm.getMaxQuestion(course_lessons, _vm.answerCourse))+" ")]):_c('div',[_vm._v("กำลังตรวจ..")])]):_vm._e()])]):_vm._e()])])]):_vm._e(),_c('br')])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }